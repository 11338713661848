import Act, { ActCode } from 'shared/domain/activity/act/model/Act';
import Recommendation, { NO_RECOMMENDATIONS } from 'app/act/recommendations/types';
import { selectSpecialty } from 'app/containers/User/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import SpecialtyName from 'shared/core/doctor/domain/SpecialtyName';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import { NatureType } from 'shared/core/activity/domain/Act';
import { ActContextElement } from 'shared/medyx-core/types/act';

export const PIN_REMOVAL_CODES = ['2301', '2304', '2306', '2309', '2312', '2317', '2318', '2793', '2974', '2998'];

export default (partialAct: Partial<Act>): Array<Recommendation<string[]>> => {
  const { codes = [], contextElements = [], place, start, type } = partialAct;

  if (contextElements.includes(ActContextElement.SDIFF)) return NO_RECOMMENDATIONS;

  const userSpecialty = selectSpecialty()(getStore().getState());

  if (userSpecialty !== SpecialtyName.ORTHOPEDIC_SURGEON) return NO_RECOMMENDATIONS;

  if (type !== ActivityType.ACT) return NO_RECOMMENDATIONS;

  if (codes.length <= 1) return NO_RECOMMENDATIONS;

  const pinRemovalCodes = codes.filter((code) => PIN_REMOVAL_CODES.includes(code.code));

  if (pinRemovalCodes.length === 0) return NO_RECOMMENDATIONS;

  if (pinRemovalCodes.length === 1 && isEveryOtherCodeVisitOrSupplement(codes)) return NO_RECOMMENDATIONS;

  return [
    {
      apply: ({ contextElements = [] }) => ({
        fieldName: 'contextElements',
        newValue: [...(contextElements ?? []), ActContextElement.SDIFF],
        oldValue: contextElements
      }),
      message:
        "Ce code ne peut être associé à un autre acte au même site, cochez pour ajouter l'élément de contexte 'site différent'."
    }
  ];
};

const isEveryOtherCodeVisitOrSupplement = (codes: ActCode[]): boolean => {
  const otherCodes = codes.filter((code) => !PIN_REMOVAL_CODES.includes(code.code));

  return otherCodes.every((code) => code.isSupplement || code.natureType === NatureType.VISIT);
};
